import axios from 'axios'
import authHeader from './auth-header'
import Constants from '../utils/Constants'

const API_URL = process.env.REACT_APP_API_BASE_URL
//references https://www.bezkoder.com/react-hooks-redux-login-registration-example/
const user = async () => {
  try {
    const response = await axios.get(API_URL + 'user', {
      headers: authHeader()
    })
    if (window.location.href.includes('/#/login')) {
      window.location.href = '/#/dashboard'
    }

    return response.data
  } catch (error) {
    logout()
  }
}

const login = async (username, password) => {
  const response = await axios.post(API_URL + 'login', {
    email: username,
    password: password
  })
  if (response.data?.data?.token) {
    localStorage.setItem('token', response.data.data.token)
    localStorage.setItem('name', response.data.data.name)
  }
  if (response.data.status === Constants.RESPONSE_STATUS_SUCCESS) {
    window.location.href = '/#/dashboard'
    return {...response.data, message: 'Berhasil Login'}
  } else {
    return {...response.data, message: 'Email / Password salah'}
  }
}

const logout = async () => {
  try {
    const response = await axios.get(API_URL + 'logout', {
      headers: authHeader()
    })
    if (response.data.status === Constants.RESPONSE_STATUS_SUCCESS) {
      localStorage.removeItem('token')
      if (!window.location.href.includes('/#/login')) {
        window.location.href = '/#/login'
      }
    }
    return response.data
  } catch (error) {
    localStorage.removeItem('token')
    if (!window.location.href.includes('/#/login')) {
      window.location.href = '/#/login'
    }
  }


}
export default user
export {user, login, logout}
